@import '~@angular/material/theming';
@include mat-core();

$app-primary: mat-palette($mat-indigo);
$app-accent: mat-palette($mat-pink, A200, A100, A400);

$app-warn: mat-palette($mat-red);

$app-theme: mat-light-theme($app-primary, $app-accent, $app-warn);

@include angular-material-theme($app-theme);

:root {
  --brand: 'selforder';
  /** primary **/
	--ion-color-primary: #45B8AC;
	--ion-color-primary-rgb: 69,184,172;
	--ion-color-primary-contrast: #000000;
	--ion-color-primary-contrast-rgb: 0,0,0;
	--ion-color-primary-shade: #3da297;
	--ion-color-primary-tint: #58bfb4;

  /** secondary **/
	--ion-color-secondary: #EDF5F3;
	--ion-color-secondary-rgb: 237,245,243;
	--ion-color-secondary-contrast: #000000;
	--ion-color-secondary-contrast-rgb: 0,0,0;
	--ion-color-secondary-shade: #d1d8d6;
	--ion-color-secondary-tint: #eff6f4;

  /** tertiary **/
  --ion-color-tertiary: #7044ff;
  --ion-color-tertiary-rgb: 112, 68, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #633ce0;
  --ion-color-tertiary-tint: #7e57ff;

  /** payment-success **/
  --ion-color-success: #10dc60;
  --ion-color-success-rgb: 16, 220, 96;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #0ec254;
  --ion-color-success-tint: #28e070;

  /** warning **/
  --ion-color-warning: #ffce00;
  --ion-color-warning-rgb: 255, 206, 0;
  --ion-color-warning-contrast: #ffffff;
  --ion-color-warning-contrast-rgb: 255, 255, 255;
  --ion-color-warning-shade: #e0b500;
  --ion-color-warning-tint: #ffd31a;

  /** danger **/
  --ion-color-danger: #b32013;
  --ion-color-danger-rgb: 179,32,19;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255,255,255;
  --ion-color-danger-shade: #9e1c11;
  --ion-color-danger-tint: #bb362b;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 34, 34;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152, 154, 162;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;

  /** light **/
  --ion-color-light: #F7F9F9;
	--ion-color-light-rgb: 247,249,249;
	--ion-color-light-contrast: #000000;
	--ion-color-light-contrast-rgb: 0,0,0;
	--ion-color-light-shade: #d9dbdb;
	--ion-color-light-tint: #f8fafa;

  --ion-color-main: #212121;
  --ion-color-main-rgb: 33,33,33;
  --ion-color-main-contrast: #ffffff;
  --ion-color-main-contrast-rgb: 255,255,255;
  --ion-color-main-shade: #1d1d1d;
  --ion-color-main-tint: #373737;

  --ion-color-second: #7A7A7A;
  --ion-color-second-rgb: 122,122,122;
  --ion-color-second-contrast: #ffffff;
  --ion-color-second-contrast-rgb: 255,255,255;
  --ion-color-second-shade: #6b6b6b;
  --ion-color-second-tint: #878787;

  --ion-color-gray-light: #F5F5F5;
  --ion-color-gray-dark: #D4D4D4;
  --ion-color-gray-darkest: #1F1B1C;

  --ion-color-black: #222222;
  --ion-color-gold: #A38A5F;

  --ion-default-font: AppFont, serif !important;
  --ion-text-color: var(--ion-color-black);


  --quantity-selector-border-width: 1px;
  --quantity-selector-border-color: #ECECEC;
  --quantity-selector-rectangle: var(--ion-color-main);
  --quantity-selector-square: var(--ion-color-main);
  --quantity-selector-font-weight: 700;
  --quantity-selector-height: 40px;
  --quantity-selector-width: 25px;
  --quantity-selector-background: white;
  --quantity-selector-text-color: var(--ion-color-primary);
  --quantity-selector-family: AppFontBold, sans-serif;
  --quantity-selector-font-size: 16px;
  --ion-color-payment-page: var(--ion-color-secondary);
  --border-radius-payment-modal: 0px;
  --table-selection-subtitle:#858585;
  --tip-background-title-background: #EBF0F0;
  --tip-border-radius: 0px;
}

.ion-color-main-color {
  --ion-color-base: var(--ion-color-main);
  --ion-color-base-rgb: var(--ion-color-main-rgb);
  --ion-color-contrast: var(--ion-color-main-contrast);
  --ion-color-contrast-rgb: var(--ion-color-main-contrast-rgb);
  --ion-color-shade: var(--ion-color-main-shade);
  --ion-color-tint: var(--ion-color-main-tint);
}

.ion-color-second-color {
  --ion-color-base: var(--ion-color-second);
  --ion-color-base-rgb: var(--ion-color-second-rgb);
  --ion-color-contrast: var(--ion-color-second-contrast);
  --ion-color-contrast-rgb: var(--ion-color-second-contrast-rgb);
  --ion-color-shade: var(--ion-color-second-shade);
  --ion-color-tint: var(--ion-color-second-tint);
}

.ion-color-black {
  --ion-color-base: var(--ion-color-black);
  --ion-color-base-rgb: var(--ion-color-black);
  --ion-color-contrast: var(--ion-color-main-contrast);
  --ion-color-contrast-rgb: var(--ion-color-main-contrast-rgb);
  --ion-color-shade: var(--ion-color-main-shade);
  --ion-color-tint: var(--ion-color-main-tint);
}

html, body {
  height: 100%;
  font-family: AppFont, "Helvetica Neue", sans-serif;
}

body {
  margin: 0;
}

